<template>
  <el-dialog
    title="选择车辆"
    :visible.sync="show"
    width="40%"
    top="5vh"
    :before-close="handleClose"
  >

    <CarList
      @change="handleChange"
      :defaultData="defaultData"
      :show="show"
      :carId="carId"
    />

  </el-dialog>
</template>

<script>
import CarList from "./selectLink/car-list.vue";
export default {
  name: "index",
  components: {
    CarList,
  },
  props: {
    defaultData: {
      type: Array,
    },
    show: {
      type: Boolean,
      default: false,
    },
    carId: {
      type: String,
    },
  },
  created() {},
  data() {
    return {
      fff: [],
    };
  },
  methods: {
    handleChange(e) {
      this.$emit("change", e);
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
</style>