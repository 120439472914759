<template>
  <div>
    <avue-form
      ref="form"
      v-model="form"
      :option="formOption"
    >
      <template slot="regionRule">
        <div v-html="form.regionRule"></div>
      </template>
    </avue-form>

  </div>
</template>

<script>
export default {
  name: "createThree",
  data() {
    return {
      form: {
        regionRule: "",
      },
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: "计费规则设置",
            prop: "regionRule",
            span: 24,
            row: true,
          },
        ],
      },
      options: {
        action: this.actionUrl,
        customConfig: {}, //wangEditor编辑的配置
        headers: {
          Authorization: this.$store.state.user.Token,
        },
        props: {
          url: "path",
          res: "data",
          name: "path",
        },
      },
    };
  },
  methods: {
    async setForm(form) {
      this.form = form;
    },
    submitForm() {
      return new Promise((resolve) => {
        this.$refs.form.validate((valid, done) => {
          if (valid) {
            done();
            resolve(this.form);
          } else {
            done();
            resolve(false);
          }
        });
      });
    },
  },
};
</script>

<style scoped></style>
