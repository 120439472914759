<template>
  <div>
    <h3>车型管理</h3>
    <avue-form
      ref="form"
      v-model="dataFour"
      :option="formOption"
    >
      <template slot="carTypes">
        <div class="main-content">
          <avue-crud
            :data="dataFour.carTypes"
            :option="option"
            :table-loading="showLoading"
          >
            <!-- <template #header>
              <div class="flex align-items marginBottom20">
                <el-button
                  size="medium"
                  type="primary"
                  icon="el-icon-plus"
                  @click="handleCreate"
                >添加车型</el-button>
              </div>
            </template> -->

            <template
              slot="bufferNum"
              slot-scope="{ row }"
            >
              {{ row.bufferNum }}
            </template>

            <template
              slot="orderFirstRemind"
              slot-scope="{ row }"
            >
              {{ row.orderFirstRemind }}
            </template>

            <template
              slot="orderTwoRemind"
              slot-scope="{ row }"
            >
              {{ row.orderTwoRemind }}
            </template>

            <template
              slot="lowBatteryRemind"
              slot-scope="{ row }"
            >
              {{ row.lowBatteryRemind }}
            </template>

            <template
              slot="lowBatteryNoUse"
              slot-scope="{ row }"
            >
              {{ row.lowBatteryNoUse }}
            </template>

            <template
              slot="lockTime"
              slot-scope="{ row }"
            >
              {{ row.lockTime }}
            </template>

            <template
              slot="workerMoveCar"
              slot-scope="{ row }"
            >
              {{ row.workerMoveCar }}
            </template>

            <template
              slot="dayUseTimeSlotEnd"
              slot-scope="{ row }"
            >
              <div class="flex">
                <div class="width200">
                  {{ row.dayUseTimeSlotStart }}
                </div>

                <div style="margin:0 10px">
                  -
                </div>
                <div class="width200">
                  {{ row.dayUseTimeSlotEnd }}
                </div>
              </div>
            </template>

            <template
              slot="menu"
              slot-scope="{ row }"
            >
              <!-- <el-button
                type="text"
                size="mini"
                @click="handleCreateCarList(row)"
              >添加车辆</el-button> -->
              <el-button
                type="text"
                size="mini"
                @click="handleFreeze(row)"
              >{{ row.state==1?'冻结':row.state==2?'解冻':'' }}</el-button>
              <el-button
                type="text"
                size="mini"
                @click="handleUnify(row,1)"
              >统一启动</el-button>
              <el-button
                type="text"
                size="mini"
                @click="handleUnify(row,2)"
              >统一上锁</el-button>
              <!-- <el-button
                type="text"
                size="mini"
                @click="handleDelete(row)"
              >删除</el-button> -->
            </template>
          </avue-crud>

        </div>
      </template>

    </avue-form>

    <CarType
      :show="dialogVisibleCarType"
      :defaultData="form.carTypes"
      @change="handleSelectData"
      @close="handleSelectClose"
    />

    <CreateCar
      :show="dialogVisibleCarList"
      :defaultData="carList"
      @change="handleSelectDataCarList"
      @close="handleSelectCloseCarList"
      :carId="carId"
    />

  </div>

</template>

<script>
import CarType from "./carType/index.vue";
import CreateCar from "./createCar/index.vue";
import {
  addCarList,
  stateCarList,
  unifyUpdateCarList,
  deleteCarList,
} from "@/api/region/manage";

export default {
  name: "index",
  props: {
    dataFour: {
      type: Object,
      default: () => {},
    },
    dataFive: {
      type: Array,
    },
  },
  components: {
    CarType,
    CreateCar,
  },
  data() {
    return {
      ids: 0,
      tableData: [],
      showLoading: false,
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 0,
        column: [
          {
            label: "",
            prop: "carTypes",
            maxlength: 20,
            showWordLimit: true,
            span: 24,
            row: true,
            // rules: [
            //   {
            //     required: true,
            //     message: "请选择车辆",
            //     trigger: "blur",
            //   },
            // ],
          },
        ],
      },
      option: {
        width: 2200,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: false, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "车型编号",
            prop: "code",
            width: 200,
          },
          {
            label: "车型名称",
            prop: "name",
            width: 100,
          },
          {
            label: "速度档位",
            prop: "gearSpeed",
            width: 100,
            formatter: function (row, column, cellValue) {
              return row.gearSpeed == 1 ? "低档" : row.gearSpeed == 2 ? "高档" : "";
            },
          },
          {
            label: "档位速度(km/h)",
            prop: "gearSpeed",
            width: 200,
            formatter: function (row, column, cellValue) {
              return row.gearSpeed == 1
                ? row.lowGearSpeed
                : row.gearSpeed == 2
                ? row.highGearSpeed
                : "";
            },
          },
          {
            label: "电子围栏缓冲区（米）",
            prop: "bufferNum",
            width: 100,
          },
          {
            label: "第一次订单倒计时提醒设置（分钟）",
            prop: "orderFirstRemind",
            width: 150,
          },
          {
            label: "第二次订单倒计时提醒设置（分钟）",
            prop: "orderTwoRemind",
            width: 300,
          },
          {
            label: "低电量提醒（%）",
            prop: "lowBatteryRemind",
            width: 200,
          },
          {
            label: "低电量无法解锁使用（%）",
            prop: "lowBatteryNoUse",
            width: 200,
          },
          {
            label: "预约呼叫锁定时间（分钟）",
            prop: "lockTime",
            width: 200,
          },
          {
            label: "工作人员挪车时间（分钟）",
            prop: "workerMoveCar",
            width: 200,
          },
          {
            label: "每日可用时间段",
            prop: "dayUseTimeSlotEnd",
            width: 200,
          },
          {
            label: "已有车辆",
            prop: "carNum",
            width: 100,
            // formatter: function (row, column, cellValue) {
            //   return row?.carId?.length;
            // },
          },
          {
            label: "状态",
            prop: "state",
            width: 100,
            formatter: function (row, column, cellValue) {
              return row.state == 1 ? "正常" : row.state == 2 ? "冻结" : "";
            },
          },
        ],
      },
      dialogVisibleCarType: false,
      dialogVisibleCarList: false,
      form: {
        carTypes: [],
      },
      carId: "",
      carList: [],
    };
  },
  created() {
    this.form = this.dataFour;
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    onDataFourHandle() {
      const data = {
        dataFour: this.form,
        dataFive: this.dataFive,
      };

      this.$emit("onDataFourHandle", data);
    },
    // 添加
    handleCreate() {
      this.dialogVisibleCarType = true;
    },
    // 冻结
    handleFreeze(row) {
      console.log("row", row);
      const state = row.state == 1 ? "冻结" : row.state == 2 ? "解冻" : "";
      this.$confirm(`确定${state}吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // this.form.carTypes = this.form.carTypes.map((item) => {
        //   if (item.id === row.id) {
        //     return {
        //       ...item,
        //       state: state === "冻结" ? "2" : state == "解冻" ? "1" : "",
        //     };
        //   }

        //   return item;
        // });
        stateCarList({
          id: row.ids1,
          state: row.state == 1 ? 2 : row.state == 2 ? 1 : "",
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success(`${state}成功!`);
            this.onDataFourHandle();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 删除
    handleDelete(row) {
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // this.form.carTypes = this.form.carTypes.filter(
        //   (item) => item.id !== row.id
        // );
        // this.dataFive = this.dataFive.filter((item) => item.carId !== row.id);

        deleteCarList({
          id: row.ids,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success(`删除成功!`);
            this.onDataFourHandle();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    handleUnify(row, startState) {
      const state = startState === 1 ? "统一启动" : "统一上锁";
      this.$confirm(`确定${state}吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // this.form.carTypes = this.form.carTypes.filter(
        //   (item) => item.id !== row.id
        // );
        // this.dataFive = this.dataFive.filter((item) => item.carId !== row.id);
        unifyUpdateCarList({
          id: row.ids1,
          startState,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success(`${state}成功!`);
            this.onDataFourHandle();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 选择车型
    handleSelectData(e) {
      const filterDataFive = this.dataFive?.filter(
        (item) => item?.carId === this?.carId
      );
      if (e?.length >= filterDataFive?.length) {
        this.form.carTypes = e.map((item, index) => {
          const indexs = this.form?.carTypes[index];
          if (indexs?.id === item.id) {
            return {
              ...this.form?.carTypes[index],
              carId: indexs?.carId,
              bufferNum: indexs?.bufferNum,
              orderFirstRemind: indexs?.orderFirstRemind,
              orderTwoRemind: indexs?.orderTwoRemind,
              lowBatteryRemind: indexs?.lowBatteryRemind,
              lowBatteryNoUse: indexs?.lowBatteryNoUse,
              lockTime: indexs?.lockTime,
              workerMoveCar: indexs?.workerMoveCar,
              dayUseTimeSlotStart: indexs?.dayUseTimeSlotStart,
              dayUseTimeSlotEnd: indexs?.dayUseTimeSlotEnd,
            };
          }
          return item;
        });
        console.log(" this.form.carTypes", this.form.carTypes);
      } else {
        console.log("wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww");
        const carTypes = this.form.carTypes.map((item) => {
          let carTypesList = e.find((items) => items.id === item.id);
          if (carTypesList) {
            return {
              ...item,
              carId: item?.carId,
              bufferNum: item?.bufferNum,
              orderFirstRemind: item?.orderFirstRemind,
              orderTwoRemind: item?.orderTwoRemind,
              lowBatteryRemind: item?.lowBatteryRemind,
              lowBatteryNoUse: item?.lowBatteryNoUse,
              lockTime: item?.lockTime,
              workerMoveCar: item?.workerMoveCar,
              dayUseTimeSlotEnd: item?.dayUseTimeSlotEnd,
            };
          }
        });
        this.form.carTypes = carTypes.filter((item) => item);
        console.log(e, carTypes, this.form.carTypes);
      }

      this.dataFive = [...this.dataFive];
      this.onDataFourHandle();
      this.handleSelectClose();
    },
    // 关闭车型弹窗
    handleSelectClose() {
      this.dialogVisibleCarType = false;
    },
    // 选择车辆
    handleSelectDataCarList(e) {
      // if (e?.length > 0) {
      //   this.form.carTypes = this.form.carTypes.map((item) => {
      //     return {
      //       ...item,
      //       carId: item.id == this.carId ? e : item.carId,
      //     };
      //   });

      //   const dataFive = e?.map((item) => {
      //     return {
      //       ...item,
      //       carId: this.carId,
      //     };
      //   });
      //   const newDataFive = this.dataFive.concat(dataFive);
      //   this.dataFive = this.noRepeat(newDataFive);

      //   const filterDataFive = this.dataFive?.filter(
      //     (item) => item?.carId === this?.carId
      //   );
      //   // console.log(
      //   //   "filterDataFivefilterDataFivefilterDataFive",
      //   //   filterDataFive,
      //   //   filterDataFive?.length,
      //   //   e,
      //   //   e?.length
      //   // );
      //   if (filterDataFive?.length > e?.length) {
      //     const dataFiveList = this.dataFive.filter(
      //       (item) => item.carId !== this.carId
      //     );
      //     this.dataFive = dataFiveList.concat(e);
      //     this.dataFive = this.noRepeat(this.dataFive);
      //   }
      // } else {
      //   this.form.carTypes = this.form.carTypes.map((item) => {
      //     return {
      //       ...item,
      //       carId: item.id == this.carId ? [] : item.carId,
      //     };
      //   });
      //   this.dataFive = this.dataFive.filter(
      //     (item) => item.carId !== this.carId
      //   );
      // }
      addCarList({
        id: this.ids,
        carIds: e.map((item) => item.id),
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success(`添加车辆成功!`);
          this.carList = [];
          this.onDataFourHandle();
          this.handleSelectCloseCarList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 关闭车辆弹窗
    handleSelectCloseCarList() {
      this.dialogVisibleCarList = false;
    },
    salaryChange(e, t, max, index) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max;
            e.target.value = max;
          } else {
            if (t === "lockTime" && value < 1) {
              this.form.carTypes[index].lockTime = 1;
              e.target.value = 1;
              return;
            }
            this.form[t] = value * 1;
            e.target.value = value * 1;
          }
        }
      } else {
        this.form[t] = "";
      }

      if (t === "bufferNum") {
        console.log("this.form.carTypes[index]", this.form.carTypes[index]);
        this.form.carTypes[index].bufferNum = e.target.value;
      }
      if (t === "orderFirstRemind") {
        this.form.carTypes[index].orderFirstRemind = e.target.value;
      }
      if (t === "orderTwoRemind") {
        this.form.carTypes[index].orderTwoRemind = e.target.value;
      }
      if (t === "lowBatteryRemind") {
        this.form.carTypes[index].lowBatteryRemind = e.target.value;
      }
      if (t === "lowBatteryNoUse") {
        this.form.carTypes[index].lowBatteryNoUse = e.target.value;
      }
      if (t === "lockTime") {
        this.form.carTypes[index].lockTime = e.target.value;
      }
    },

    noRepeat(arr) {
      for (var i = 0; i < arr?.length; i++) {
        for (var j = 0; j < arr?.length; j++) {
          if (arr[i].id == arr[j].id && i != j) {
            arr.splice(j, 1);
          }
        }
      }
      return arr;
    },

    handleCreateCarList(row) {
      this.ids = row.ids;
      this.carId = row.id;
      this.carList = row?.carId;
      this.dialogVisibleCarList = true;
    },
  },
};
</script>

<style scoped>
</style>