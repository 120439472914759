<template>
  <div>
    <h3>套餐设置</h3>
    <avue-form
      ref="form"
      v-model="form"
      :option="formOption"
    >
      <template slot="carTypes">
        <div class="main-content">
          <avue-crud
            :data="dataFour"
            :option="option"
            :table-loading="showLoading"
          >

            <template
              slot="illegalStop"
              slot-scope="{ row }"
            >
              {{ row.illegalStop }}
            </template>

            <template
              slot="packageNameOne"
              slot-scope="{ row }"
            >
              {{ row.packageNameOne }}
            </template>

            <template
              slot="packageDurationOne"
              slot-scope="{ row }"
            >
              {{ row.packageDurationOne }}
            </template>

            <template
              slot="packagePriceOne"
              slot-scope="{ row }"
            >
              {{ row.packagePriceOne }}
            </template>

            <template
              slot="packageMinAvailablePowerOne"
              slot-scope="{ row }"
            >
              {{ row.packageMinAvailablePowerOne }}
            </template>

            <template
              slot="packageUseTimeOne"
              slot-scope="{ row }"
            >
              <div class="flex justify-content-center ">
                <div class="width100">
                  {{ row.packageUseTimeOneStart }} - {{ row.packageUseTimeOneEnd }}
                </div>
              </div>
            </template>

            <template
              slot="packageNameTwo"
              slot-scope="{ row }"
            >
              {{ row.packageNameTwo }}
            </template>

            <template
              slot="packageDurationTwo"
              slot-scope="{ row }"
            >
              {{ row.packageDurationTwo }}
            </template>

            <template
              slot="packagePriceTwo"
              slot-scope="{ row }"
            >
              {{ row.packagePriceTwo }}
            </template>

            <template
              slot="packageMinAvailablePowerTwo"
              slot-scope="{ row }"
            >
              {{ row.packageMinAvailablePowerTwo }}
            </template>

            <template
              slot="packageUseTimeTwo"
              slot-scope="{ row }"
            >
              <div class="flex justify-content-center">
                <div class="width100">
                  {{ row.packageUseTimeTwoStart }} - {{ row.packageUseTimeTwoEnd }}
                </div>
              </div>
            </template>

            <template
              slot="packageNameThree"
              slot-scope="{ row }"
            >
              {{ row.packageNameThree }}
            </template>

            <template
              slot="packageDurationThree"
              slot-scope="{ row }"
            >
              {{ row.packageDurationThree }}
            </template>

            <template
              slot="packagePriceThree"
              slot-scope="{ row }"
            >
              {{ row.packagePriceThree }}
            </template>

            <template
              slot="packageMinAvailablePowerThree"
              slot-scope="{ row }"
            >
              {{ row.packageMinAvailablePowerThree }}
            </template>

            <template
              slot="packageUseTimeThree"
              slot-scope="{ row }"
            >
              <div class="flex justify-content-center">
                <div class="width100">
                  {{ row.packageUseTimeThreeStart }} - {{ row.packageUseTimeThreeEnd }}
                </div>
              </div>
            </template>

            <template
              slot="packageNameFour"
              slot-scope="{ row }"
            >
              {{row.packageNameFour}}
            </template>

            <template
              slot="packageDurationFour"
              slot-scope="{ row }"
            >
              {{ row.packageDurationFour }}
            </template>

            <template
              slot="packagePriceFour"
              slot-scope="{ row }"
            >
              {{ row.packagePriceFour }}
            </template>

            <template
              slot="packageMinAvailablePowerFour"
              slot-scope="{ row }"
            >
              {{ row.packageMinAvailablePowerFour }}
            </template>

            <template
              slot="packageUseTimeFour"
              slot-scope="{ row }"
            >
              <div class="flex justify-content-center">
                <div class="width100">
                  {{ row.packageUseTimeFourStart }} - {{ row.packageUseTimeFourEnd }}
                </div>
              </div>
            </template>

            <template
              slot="packageNameFive"
              slot-scope="{ row }"
            >
              {{ row.packageNameFive }}
            </template>

            <template
              slot="packageDurationFive"
              slot-scope="{ row }"
            >
              {{ row.packageDurationFive }}
            </template>

            <template
              slot="packagePriceFive"
              slot-scope="{ row }"
            >
              {{ row.packagePriceFive }}
            </template>

            <template
              slot="packageMinAvailablePowerFive"
              slot-scope="{ row }"
            >
              {{  row.packageMinAvailablePowerFive}}
            </template>

            <template
              slot="packageUseTimeFive"
              slot-scope="{ row }"
            >
              <div class="flex justify-content-center">
                <div class="width100">
                  {{ row.packageUseTimeFiveStart }} - {{ row.packageUseTimeFiveEnd }}
                </div>
              </div>
            </template>

          </avue-crud>

        </div>
      </template>

    </avue-form>

  </div>

</template>

<script>
export default {
  name: "index",
  props: {
    dataFour: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tableData: [],
      showLoading: false,
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 0,
        column: [
          {
            label: "",
            prop: "carTypes",
            maxlength: 20,
            showWordLimit: true,
            span: 24,
            row: true,
            // rules: [
            //   {
            //     required: true,
            //     message: "请选择车辆",
            //     trigger: "blur",
            //   },
            // ],
          },
        ],
      },
      option: {
        width: 20000,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: false, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        menu: false,
        column: [
        {
            label: "车型名称",
            prop: "carTypeName",
            width: 140,
          },
          {
            label: "套餐类型",
            prop: "packageType",
            width: 80,
            type: "select",
            dicData: [
              {
                label: "普通订单",
                value: 1,
              },
              {
                label: "续费订单",
                value: 2,
              },
            ],
          },
          {
            label: "违停费（元）",
            prop: "illegalStop",
            width: 200,
          },
          {
            label: "套餐1名称",
            prop: "packageNameOne",
            width: 240,
          },
          {
            label: "套餐1时长（分钟）",
            prop: "packageDurationOne",
            width: 200,
          },
          {
            label: "套餐1收费（元）",
            prop: "packagePriceOne",
            width: 200,
          },
          {
            label: "套餐1最低可用电量（%）",
            prop: "packageMinAvailablePowerOne",
            width: 200,
          },
          {
            label: "套餐1可使用时间",
            prop: "packageUseTimeOne",
            width: 340,
          },
          {
            label: "套餐2名称",
            prop: "packageNameTwo",
            width: 240,
          },
          {
            label: "套餐2时长（分钟）",
            prop: "packageDurationTwo",
            width: 200,
          },
          {
            label: "套餐2收费（元）",
            prop: "packagePriceTwo",
            width: 200,
          },
          {
            label: "套餐2最低可用电量（%）",
            prop: "packageMinAvailablePowerTwo",
            width: 200,
          },
          {
            label: "套餐2可使用时间",
            prop: "packageUseTimeTwo",
            width: 340,
          },
          {
            label: "套餐3名称",
            prop: "packageNameThree",
            width: 240,
          },
          {
            label: "套餐3时长（分钟）",
            prop: "packageDurationThree",
            width: 200,
          },
          {
            label: "套餐3收费（元）",
            prop: "packagePriceThree",
            width: 200,
          },
          {
            label: "套餐3最低可用电量（%）",
            prop: "packageMinAvailablePowerThree",
            width: 200,
          },
          {
            label: "套餐3可使用时间",
            prop: "packageUseTimeThree",
            width: 340,
          },
          {
            label: "套餐4名称",
            prop: "packageNameFour",
            width: 240,
          },
          {
            label: "套餐4时长（分钟）",
            prop: "packageDurationFour",
            width: 200,
          },
          {
            label: "套餐4收费（元）",
            prop: "packagePriceFour",
            width: 200,
          },
          {
            label: "套餐4最低可用电量（%）",
            prop: "packageMinAvailablePowerFour",
            width: 200,
          },
          {
            label: "套餐4可使用时间",
            prop: "packageUseTimeFour",
            width: 340,
          },
          {
            label: "套餐5名称",
            prop: "packageNameFive",
            width: 240,
          },
          {
            label: "套餐5时长（分钟）",
            prop: "packageDurationFive",
            width: 200,
          },
          {
            label: "套餐5收费（元）",
            prop: "packagePriceFive",
            width: 200,
          },
          {
            label: "套餐5最低可用电量（%）",
            prop: "packageMinAvailablePowerFive",
            width: 200,
          },
          {
            label: "套餐5可使用时间",
            prop: "packageUseTimeFive",
            width: 340,
          },
        ],
      },
      form: {
        carTypes: [],
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }

    console.log("this.dataFour", this.dataFour);
  },
  methods: {
    salaryChange(e, t, max, index) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max;
            e.target.value = max;
          } else {
            if (t === "packageDurationOne" && value < 3) {
              this.dataFour.carTypes[index].packageDurationOne = 3;
              e.target.value = 3;
              return;
            }
            this.form[t] = value * 1;
            e.target.value = value * 1;
          }
        }
      } else {
        this.form[t] = "";
      }

      if (t === "packageDurationOne") {
        this.dataFour.carTypes[index].packageDurationOne = e.target.value;
      }
      if (t === "packageMinAvailablePowerOne") {
        this.dataFour.carTypes[index].packageMinAvailablePowerOne =
          e.target.value;
      }
      if (t === "packageDurationTwo") {
        this.dataFour.carTypes[index].packageDurationTwo = e.target.value;
      }
      if (t === "packageMinAvailablePowerTwo") {
        this.dataFour.carTypes[index].packageMinAvailablePowerTwo =
          e.target.value;
      }
      if (t === "packageDurationThree") {
        this.dataFour.carTypes[index].packageDurationThree = e.target.value;
      }
      if (t === "packageMinAvailablePowerThree") {
        this.dataFour.carTypes[index].packageMinAvailablePowerThree =
          e.target.value;
      }
      if (t === "packageDurationFour") {
        this.dataFour.carTypes[index].packageDurationFour = e.target.value;
      }
      if (t === "packageMinAvailablePowerFour") {
        this.dataFour.carTypes[index].packageMinAvailablePowerFour =
          e.target.value;
      }
      if (t === "packageDurationFive") {
        this.dataFour.carTypes[index].packageDurationFive = e.target.value;
      }
      if (t === "packageMinAvailablePowerFive") {
        this.dataFour.carTypes[index].packageMinAvailablePowerFive =
          e.target.value;
      }
    },

    salaryDianChange(e, t, max, index) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max.toFixed(2);
            e.target.value = max.toFixed(2);
          } else {
            this.form[t] = parseFloat(value).toFixed(2);
            e.target.value = parseFloat(value).toFixed(2);
          }
        }
      } else {
        this.form[t] = "";
      }
      if (t === "illegalStop") {
        this.dataFour.carTypes[index].illegalStop = e.target.value;
      }
      if (t === "packagePriceOne") {
        this.dataFour.carTypes[index].packagePriceOne = e.target.value;
      }
      if (t === "packagePriceTwo") {
        this.dataFour.carTypes[index].packagePriceTwo = e.target.value;
      }
      if (t === "packagePriceThree") {
        this.dataFour.carTypes[index].packagePriceThree = e.target.value;
      }
      if (t === "packagePriceFour") {
        this.dataFour.carTypes[index].packagePriceFour = e.target.value;
      }
      if (t === "packagePriceFive") {
        this.dataFour.carTypes[index].packagePriceFive = e.target.value;
      }
    },
  },
};
</script>

<style scoped>
</style>